<template>
    <div>
        <template v-if="$route.params.siteId && $route.params.userId && $route.params.siteName">
            <router-view></router-view>
        </template>
        <v-col v-else class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 pb-0">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Harmonys</h1>
                    <p class="desc mt-0 mb-1">Aquí puedes ver las estadísticas diarias de los Harmony, reflejando el rendimiento de cada día.</p>
                </v-alert>
            </v-col>

            <!-- Search Bar y Filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field
                        v-model="search"
                        @input="fetchWppOrEmails"
                        color="#8061c2"
                        label="Buscar"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        outlined
                        class="mb-2 mx-0 px-0"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="px-md-2 px-0">
                    <v-select
                        v-model="filter"
                        :items="filterOptions"
                        label="Filtrar por"
                        outlined
                        hide-details
                    ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="px-0 ">
                    <v-select
                        v-model="order"
                        :items="orderOptions"
                        label="Ordenar"
                        outlined
                        hide-details
                    ></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-data-table
                :headers="headers"
                :items="wppOrEmails"
                :loading="loading"
                class="elevation-1 mx-3 px-2 py-4"
                :items-per-page="perPage"
                :server-items-length="totalMedia"
                :page.sync="page"
                :footer-props="{
                    itemsPerPageOptions: [50, 100, 200, 300, -1], 
                    showFirstLastPage: true
                }"
                @update:page="fetchWppOrEmails"
                @update:items-per-page="changePerPage"
            >
                <!-- Columnas y Templates -->
                <template v-slot:item.logo_path="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                                size="35"
                                class="clickable-avatar"
                                @click="showLogo(item.site_info.logo_path)"
                                @mouseover="hoveredLogo = item.logo_path"
                                @mouseleave="hoveredLogo = null"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-img :src="item.site_info.logo_path" v-if="item.site_info.logo_path"></v-img>
                                <v-img :src="require('../../assets/illustrations/no-photo.jpg')" v-else></v-img>
                            </v-avatar>
                        </template>
                        <span>Ver Imagen</span>
                    </v-tooltip>
                </template>



                <!-- Botones de acciones -->
                <template v-slot:item.actions="{ item }">
                    <!-- Nuevo botón para ir al Harmony -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="green" v-bind="attrs" v-on="on" @click="goToHarmony(item.site_info.slug)">
                                <v-icon>mdi-web</v-icon>
                            </v-btn>
                        </template>
                        <span>Ir al Harmony</span>
                    </v-tooltip>

                    <!-- Botón para impersonar usuario con tooltip -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(128 97 194)" v-bind="attrs" v-on="on" @click="impersonateUser(item.site_info.user_id)">
                                <v-icon>mdi-account-switch</v-icon>
                            </v-btn>
                        </template>
                        <span>Impersonar Usuario</span>
                    </v-tooltip>

                   <!-- Botón para ver los links del harmony con tooltip -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgb(223 165 27)" v-bind="attrs" v-on="on" @click="goToLinks(item.site_info.slug)">
                                <v-icon>mdi-link-variant</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver Enlaces</span>
                    </v-tooltip>

                </template>

            </v-data-table>

            <!-- Modal para ampliar el logo -->
            <v-dialog v-model="dialog" max-width="14rem" class="rounded-lg">
                <v-img :src="selectedLogo" width="14rem"></v-img>
            </v-dialog>
        </v-col>
    </div>
</template>

<script>
import axios from '@/axios.js';
import impersonationMixin from '@/mixins/impersonationMixin';

export default {
    name: "Harmonys",
    mixins: [impersonationMixin],
    data() {
        return {
            statistics: [],
            wppOrEmails: [],
            search: '',  // Término de búsqueda
            loading: true,
            dialog: false,
            selectedLogo: null,
            page: 1,
            perPage: 50,
            totalSites: 0,
            totalMedia: 0,
            order: 'desc',
            filter: 'created_at',
            headers: [
                { text: 'Logo', value: 'logo_path', align: 'start', sortable: false },
                { text: 'Harmony', value: 'site_info.name', align: 'start' },
                { text: 'Wpp o email', value: 'wpp_or_email', sortable: true },
                { text: 'Fecha', value: 'created_at', sortable: true },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            orderOptions: [
                { text: 'Mayor', value: 'desc' },
                { text: 'Menor', value: 'asc' }
            ],
            filterOptions: [
                { text: 'Fecha Creación', value: 'created_at' },
                { text: 'WhatsApp', value: 'wpp' },
                { text: 'Emails', value: 'email' },
            ],
        };
    },

    watch: {
        order(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchWppOrEmails();
            }
        },
        filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.fetchWppOrEmails();
            }
        }
    },

    created() {
        this.initializeSearch();
        this.fetchWppOrEmails();
        this.$i18n.locale = 'es';
    },
    methods: {
        initializeSearch() {
            const searchQuery = this.$route.query.search;
            if (searchQuery) {
                this.search = searchQuery;
            }
        },

        async fetchWppOrEmails() {
            this.loading = true;
            try {
                const response = await axios.get('/get-wpp-emails', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        order: this.order,
                        filter: this.filter,
                        list: 'all'
                    }
                });
                this.wppOrEmails = response.data.wppOrEmails;
                this.totalMedia = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchWppOrEmails();
        },

        goToStatistics(item) {
            const harmonyUrl = `${window.location.origin}/panel/admin/stats?id=${item.id}&user_id=${item.user.id}&name=${encodeURIComponent(item.name)}`;
            window.open(harmonyUrl, '_blank');
        },

        showLogo(logoPath) {
            this.selectedLogo = logoPath ? logoPath : require('../../assets/illustrations/no-photo.jpg');
            this.dialog = true;
        },

        goToHarmony(slug) {
            const harmonyUrl = `${window.location.origin}/${slug}`;
            window.open(harmonyUrl, '_blank');
        },

        goToLinks(slug){
            const routeData = this.$router.resolve({
                name: 'Links',
                query: { search: slug }
            });

            window.open(routeData.href, '_blank');
        }
    }
};
</script>

<style scoped>
.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.clickable-avatar:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}
</style>

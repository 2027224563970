import axios from '@/axios.js';

export default {
    methods: {
        async impersonateUser(user_id) {
            try {
              const response = await axios.post("/admin/impersonate-user", {
                user_id,
              });
              let admin = localStorage.getItem("user");
              localStorage.setItem("impersonate_admin", JSON.stringify(admin));

              // Guardar el nuevo token y datos de usuario en el local storage
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("token", response.data.token);

              this.$vs.notify({
                title: "Impersonación exitosa",
                text: `Ahora estás logueado como ${response.data.user.first_name}`,
                color: "success",
                icon: "check_box",
                position: "bottom-center",
                time: 5000,
              });

              // Redirigir al panel correspondiente basado en el rol del usuario
              if (response.data.user.role_id === 1) {
                window.location.href = "panel/admin";
              } else {
                this.$router.push("/panel");
              }
            } catch (error) {
              console.error("Error impersonating user:", error);
            }
        },
    },
};